<template>
	<div id="body">
		<div class="query">
			<div class="queryLeft">
				<div style="width:100%">
					<div style="width: 35%; ;float: left;">项目名：
						<el-select v-model="search.remark" class="select" size="medium" clearable placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value"> </el-option>
						</el-select>
					</div>
					<div style="width: 30%;float: left;">版本号：
						<el-input v-model="search.version" placeholder="请输入内容" style="width: auto;"></el-input>
					</div>
					<div style="width: 30%;float: left;">发布人：
						<el-input v-model="search.person" placeholder="请输入内容" style="width: auto;"></el-input>
					</div>
				</div>
				<div style="width: 100%;display:block;clear:both;margin:20px 0 0">发布时间：
					<el-date-picker style="width: 26%;" v-model="stime" type="daterange" size="small"
						:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" align="right" value-format="timestamp">
					</el-date-picker>
				</div>
			</div>
			<div class="queryRight" style="width: 20%;">
				<el-button @click="reset()">重置</el-button>
				<!-- <el-button type="primary" @click="$router.push({query:{page:currpage,...search,}})">查询</el-button> -->
				<el-button type="primary" @click="visionSearchZhen()">查询</el-button>
				<el-button type="info" @click="publish()">发布版本</el-button>
			</div>
		</div>
		<div class="ClassInfo_box">
			<div class="chartTitle"></div>
			<el-table :data="dataList" style="width: 100%" v-loading="loading">
				<el-table-column prop="remark" label="项目名" align="center" width="100"></el-table-column>
				<el-table-column prop="app_name" label="应用名称" align="center"></el-table-column>
				<el-table-column prop="version" label="版本号" align="center"></el-table-column>
				<el-table-column prop="type" label="更新方式" :formatter="types" align="center"></el-table-column>
				<el-table-column prop="package" label="包地址	" align="center" width="250">
					<template slot-scope="scope">
						<a :href="scope.row.package" target="_blank" style="text-decoration-line: underline;">
							{{scope.row.package}}
						</a>
					</template>
				</el-table-column>
				<el-table-column prop="update_time" label="发布时间" align="center">
					<template slot-scope="scope">
						{{$util.dateFormat(scope.row.update_time)}}
					</template>
				</el-table-column>
				<el-table-column prop="package_md5" label="MD5校验	" align="center" :formatter="md5"></el-table-column>
				<el-table-column prop="person" label="发布人" align="center"></el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :total="CountNum" :current-page.sync="currpage"
					style="margin-top: 20px">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	import md5 from 'js-md5'
	import axios from 'axios'
	import {

	} from '@/api/adminApi.js'
	export default {
    beforeRouteEnter(to, from, next) { //判断是从哪个页面跳转过来的 beforeRouteEnter 最先执行 执行后再渲染页面
    		next(vm => {  //这个next 必须放在最上面
            if (from.name == null) { // 当前页面刷新 获取缓存
              // beforeRouteEnter不能通过this访问组件实例，但是可以通过 vm 访问组件实例
              vm.search   = JSON.parse(localStorage.getItem('version_searchOk')) //vm.search即this.search
              vm.searchOk = JSON.parse(localStorage.getItem('version_searchOk'))
              vm.stime    = JSON.parse(localStorage.getItem('version_stime'))
              vm.stimeOk  = JSON.parse(localStorage.getItem('version_stime'))
            } else { //这个用来处理 从别的页面进来时
              vm.search = {};
              vm.searchOk = vm.search;
              vm.stime = [];
              vm.stimeOk = vm.stime;
              localStorage.setItem('version_searchOk', JSON.stringify(vm.searchOk))  // 并清空 所清空缓存
              localStorage.setItem('version_stime', JSON.stringify(vm.stimeOk))
              localStorage.setItem('version_page', 1)
              vm.currpage = 1 //分页页码 为第一页
            }
    		})
    },
		data() {
			return {
				search: {},   //搜索条件
				searchOk: {}, //最终搜索对象
				stime: null, //搜索时间
				stimeOk: null, //搜索时间确定
				pickerOptions: {
					// 设置不能选择的日期
					onPick: ({
						maxDate,
						minDate
					}) => {
						this.choiceDate0 = minDate.getTime();
						if (maxDate) {
							this.choiceDate0 = '';
						}
					},
					disabledDate: (time) => {
						let choiceDateTime = new Date(this.choiceDate0).getTime();
						const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
						const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
						const min = minTime;
						const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
						const max = newDate < maxTime ? newDate : maxTime;
						//如果已经选中一个日期 则 返回 该日期前后一个月时间可选
						if (this.choiceDate0) {
							return time.getTime() < min || time.getTime() > max;
						}
						//若一个日期也没选中 则 返回 当前日期以前日期可选
						return time.getTime() > newDate;
					}
				},
				dataList: [],
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				options: [{
					value: '视云格APP',
					label: '视云格APP'
				}, {
					value: '电脑验光仪',
					label: '电脑验光仪'
				}, {
					value: '安防系统',
					label: '安防系统'
				}, {
					value: '视力检测',
					label: '视力检测'
				}, {
					value: '音视频系统',
					label: '音视频系统'
				}],
			}
		},
    created() {//这个用来处理 页面刷新时 分页页码 停留在分页上
      this.currpage  = localStorage.getItem('version_page') ? parseInt(localStorage.getItem('version_page')) : 1
    },
		mounted() {
			this.visionSearch(this.currpage)
		},
		methods: {
			reset() { //重置按钮
				this.search = {};
				this.searchOk = this.search;
				this.stime = [];
				this.stimeOk = this.stime;

				localStorage.setItem('version_searchOk', JSON.stringify(this.searchOk))//清空缓存
				localStorage.setItem('version_stime', JSON.stringify(this.stimeOk))
				localStorage.setItem('version_page', this.currpage)

				this.visionSearch(this.currpage);
				// :current-page.sync="currpage" 这么写才能重置页码
			},
			visionSearch(val) { // 列表搜索

				axios.post('https://version.shiyuntech.net/v1/version/versionList', {
						page: val,
						limit: this.pagesize,
						auth_account: 'syg_app147258369',
						auth_pass: 'e10adc3949ba59abbe56e057f20f883e',
						remark: this.searchOk.remark ? this.searchOk.remark  : '',
						version: this.searchOk.version ? this.searchOk.version : '',
						person: this.searchOk.person? this.searchOk.person : '',
						startTime: this.stimeOk != null ? this.stimeOk[0] / 1000 : '',
						endTime: this.stimeOk != null ? this.stimeOk[1] / 1000 + 86399 : ''
					})
					.then(res => {
						this.dataList = res.data.data.list
						this.CountNum = res.data.data.countNum
					})
					.catch(err => {
						console.log(err)
					})
			},
			visionSearchZhen() { // 搜索 第一走这个
				// console.log(this.search.remark);
				this.searchOk = this.search
        this.stimeOk  = this.stime

				localStorage.setItem('version_searchOk', JSON.stringify(this.searchOk))
				localStorage.setItem('version_stime', JSON.stringify(this.stimeOk))
        localStorage.setItem('version_page', 1)

				this.currpage = 1
				axios.post('https://version.shiyuntech.net/v1/version/versionList', {
						page: 1,
						limit: this.pagesize,
						auth_account: 'syg_app147258369',
						auth_pass: 'e10adc3949ba59abbe56e057f20f883e',
						remark: this.search.remark ? this.search.remark : '',
						version: this.search.version ? this.search.version : '',
						person: this.search.person ? this.search.person : '',
						startTime: this.stime != null ? this.stime[0] / 1000 : '',
						endTime: this.stime != null ? this.stime[1] / 1000 + 86399 : '',
					})
					.then(res => {
						this.dataList = res.data.data.list
						this.CountNum = res.data.data.countNum
					})
					.catch(err => {
						console.log(err)
					})
			},
			publish() {
				this.$router.push({
					name: 'versionUpdate',
					params: {
						id: 0
					}
				})
			},
			types(row) {
				return row.type == 1 ? '整包更新 ' : '热更新'
			},
			md5(row) {
				return row.package_md5 == 1 ? '通过 ' : '未通过'
			},
			handleCurrentChange(val) { // 分页
				localStorage.setItem('version_page', val)
				this.visionSearch(val)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#body {
		min-height: 94%;
		overflow: hidden;
	}

	.header {
		display: flex;
		height: 70px;
		justify-content: space-around;

		div {
			p:nth-child(1) {
				font-size: 20px;
				font-weight: normal;
			}

			p:nth-child(2) {
				font-size: 14px;
				font-weight: normal;
				color: #000000;
			}

		}

	}

	.ClassInfo_box {
		width: 96%;
		margin: 0 auto;
		background: white;
		margin-top: 25px;

		.chartTitle {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			border-bottom: 1px solid #cccccc;

			.facility {
				font-size: 16px;
				line-height: 24px;
				color: #1890FF;
				margin-left: 5px;
				font-family: PingFangSC-Medium;
			}
		}
    a:hover{
        color: #1E90FF;
    }
	}

	.query {
		width: 98%;
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		padding-left: 32px;

		.queryLeft {
			width: 80%;
			overflow: hidden;

			div {
				width: 22%;
				text-align: left;

				.input,
				.select {
					width: 60%;
				}
			}
		}
	}

</style>
